.clsPortfolioSelect {
    .MuiInputBase-input {
        color: #fff !important;
    }
    .MuiInput-underline {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    }
    .MuiNativeSelect-icon {
        color: rgba(255, 255, 255, 0.54) !important;
    }
    .MuiInput-underline:before {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42) !important;
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 2px solid rgba(255, 255, 255, 0.87) !important;
    }
}
